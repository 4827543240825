import { useEffect, useMemo } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { app as teamsjsApp } from '@microsoft/teams-js';

import { useStore } from '../stores/Root.store';
import { TeamsUserLocale } from 'src/models/TeamsUserLocale';
import LimeadeLoader from '../components/shared/Loader/Loader';
import { MicrosoftGraphLocalization } from './MicrosoftGraphLocalization';
import { isAuthorization } from 'src/utilities/utilities';
import { useData } from 'src/lib/useData';
import { Flags } from 'src/models/FeatureFlags';
import Logger from 'src/logger/Logger';
import { Roles, TENANT_IDS_WITH_LANGUAGE_LIMITED } from 'src/utilities/constants';
import { app } from './TeamsJsWrapper';
import ProgramQuietTime from 'src/components/views/ProgramQuietTimeView/ProgramQuietTime';

export const InitializeTabsData = observer((opts: { path: string; component: any }) => {
  const { UserProfileStore, ApplicationStore, AppAuthStore } = useStore();
  const isNotAuthenticationFlow = !isAuthorization();
  const {
    loading: isProfileLoading,
    error: loadProfileError,
    reload: loadProfile,
  } = useData(() => UserProfileStore.updateProfile(), { auto: false });
  const {
    loading: isAllDeviceSyncInfoLoading,
    error: loadAllDeviceSyncInfoError,
    reload: loadAllDeviceSyncInfo,
  } = useData(() => UserProfileStore.loadAllDeviceSyncInfo(), { auto: false });
  const { reload: loadFeatureFlags, error: fetchFeatureFlagsError } = useData(
    () => ApplicationStore.fetchFeatureFlags([Flags.HideMyChoiceBanner]),
    { auto: false }
  );
  const { error: loadGlobalConsentsError, reload: loadGlobalConsents } = useData(
    () => UserProfileStore.loadGlobalConsents(),
    { auto: false }
  );
  const { error: loadTargetedConsentsError, reload: loadTargetedConsents } = useData(
    () => UserProfileStore.loadTargetedConsents(),
    { auto: false }
  );

  const isLoading = isProfileLoading || isAllDeviceSyncInfoLoading;
  const error = loadProfileError || loadAllDeviceSyncInfoError || loadGlobalConsentsError || loadTargetedConsentsError;
  const { data, isReady: isContextReady } = useData(app.getContext);
  const context: teamsjsApp.Context = data;
  const userId = context?.user?.id;
  const locale = context?.app.locale;
  const tenantId = context?.user?.tenant?.id;
  const { reload: storeUserLocale, error: storeUserLocaleError } = useData(
    async () => {
      if (!userId || !locale) {
        return;
      }

      const teamsUserLocale: TeamsUserLocale = { oid: userId, locale };

      //We still keep this logic here if we need this feature back in future.
      if (tenantId && TENANT_IDS_WITH_LANGUAGE_LIMITED.includes(tenantId)) {
        await UserProfileStore.storeUserLocale({ ...teamsUserLocale, locale: 'en' });
      } else {
        await UserProfileStore.storeUserLocale(teamsUserLocale);
      }
    },
    { auto: false }
  );

  const isValidToFetchData = useMemo(() => {
    return isNotAuthenticationFlow && AppAuthStore.isAuthenticated;
  }, [isNotAuthenticationFlow, AppAuthStore.isAuthenticated]);

  useEffect(() => {
    if (isValidToFetchData && isContextReady()) {
      storeUserLocale();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidToFetchData, context]);

  useEffect(() => {
    if (isValidToFetchData) {
      loadFeatureFlags();
      loadProfile();
      loadAllDeviceSyncInfo();
      loadGlobalConsents();
      loadTargetedConsents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidToFetchData]);

  useEffect(() => {
    // This is a special case, we dont want to interrupt user even there is an exception
    if (fetchFeatureFlagsError) {
      Logger.trackException(fetchFeatureFlagsError);
    }

    if (storeUserLocaleError) {
      Logger.trackException(storeUserLocaleError);
    }

    // For any other errors, we should display an error page
    if (error) {
      throw error;
    }
  }, [error, fetchFeatureFlagsError, storeUserLocaleError]);

  if (isLoading || (isValidToFetchData && !UserProfileStore.startupData)) {
    return <LimeadeLoader />;
  }

  if (UserProfileStore.startupData?.IsInPqt && !AppAuthStore.userRoles.includes(Roles.ProgramAdmin)) {
    return <ProgramQuietTime />;
  }

  const Component = opts.component;
  return (
    <>
      <MicrosoftGraphLocalization />
      <Route path={opts.path} render={(routeProps) => <Component {...routeProps} />} />
    </>
  );
});
